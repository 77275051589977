import { HTTP } from "@/service/http-common";
// initial state
const state = () => ({
   is_auth: false,
   token: "",
   wallet: 0,
   userName: "",
});

// getters
const getters = {
   isAuth(state) {
      return state.is_auth;
   },
   getToken(state) {
      return state.token;
   },
   getWallet(state) {
      return state.wallet;
   },
};

// actions
const actions = {
   login({ commit }, input) {
      return new Promise((resolve, reject) => {
         const bodyParameter = {
            phone: input.phone,
            password: input.password,
         };

         HTTP.post("authenticate", bodyParameter)
            .then((response) => {
               commit("setToken", "Bearer " + response.data.data.token);
               commit("setUserWallet", response.data.data.wallet);
               commit("setUserName", response.data.data.name);
               commit("setAuth", true);
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   logout({ commit }) {
      return new Promise((resolve, reject) => {

         HTTP.post("logout", "")
            .then((response) => {
               commit("setToken", "");
               commit("setUserWallet", 0);
               commit("setUserName", "");
               commit("setAuth", false);
               resolve(response);
            })
            .catch((error) => {
               commit("setToken", "");
               //commit("setAuthUser", user);
               commit("setAuth", false);
               reject(error.response.data.errors);
            });
      });
   },
   changePassword(_, input) {
      return new Promise((resolve, reject) => {
         const bodyParameter = {
            currentPassword: input.currentPassword,
            newPassword: input.newPassword,
            newPassword_confirmation: input.confirmNewPassword,
         };

         HTTP.post("change-password", bodyParameter)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error.response.data.errors);
            });
      });
   },
  
};

// mutations
const mutations = {
   setToken(state, token) {
      state.token = token;
   },
   setAuth(state, payload) {
      state.is_auth = payload;
   },
   setUserWallet(state, payload) {
      state.wallet = payload;
   },
   setUserName(state, payload) {
      state.userName = payload;
   }
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
