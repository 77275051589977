import { HTTP } from "@/service/http-common";
// initial state
const state = () => ({ });

// getters
const getters = { };

// actions
const actions = {
   get2dCloseDays(){

      return new Promise((resolve, reject) => {
         HTTP.get("2D-Closed-Days")
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   getBettingHistories(){

      return new Promise((resolve, reject) => {
         HTTP.get("2D-Bet-Histories")
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   getWeeklyResults() {

      return new Promise((resolve, reject) => {
         HTTP.get("2D-Weekly-Results")
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   getRecentResults() {
      
      return new Promise((resolve, reject) => {
         HTTP.get("2D-Recent-Results")
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   getBetted2DNumbers() {
      
      return new Promise((resolve, reject) => {
         HTTP.get("2D-Number-Lists")
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   bet2DNumbers(_, input) {
      return new Promise((resolve, reject) => {

         const bodyParameter = {
            total_amount: input.totalAmount,
            total_numbers: input.totalNumbers,
            numbers: input.numArr,
         };

         HTTP.post("2D-Bet", bodyParameter)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
};

// mutations
const mutations = { };

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
