<template>
  <!-- <div v-if="isOnline" class="text-slate-100">online</div>
  <div v-else>No internet connection found</div> -->
  <header class="py-4 bg-slate-900 w-full z-50 lg:block">
    <nav class="container mx-auto flex justify-between items-center">
      <!-- <h1 class="font-bold text-xl text-slate-50 italic cursor-pointer">SLNL</h1> -->
      <img src="../assets/img/slnl.svg" alt="" @click="redirectPage('')" class="cursor-pointer 
      lg:w-28 w-20 h-auto bg-blue-900 px-2 rounded-lg py-1 ring-yellow-600 ring-1">
      <div v-show="login">
        <button class="px-3 py-2 bg-yellow-400 text-slate-900 rounded text-sm tracking-normal 
          flex items-center space-x-1" @click="redirectPage('login')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
            class="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round"
              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
          </svg>
          <span class="uppercase">အကောင့်ဝင်ရန်</span>
        </button>
      </div>
      <div class="flex space-x-3 items-center" v-show="register">
        <div class="flex justify-between items-center gap-3 rounded p-1">
          <div class="flex space-x-3 items-center">
            <div class="lg:flex hidden justify-between items-center bg-slate-200 rounded">
              <div class="bg-blue-800 rounded-sm p-[6px] flex items-center gap-x-2">
                <i class='bx bxs-wallet-alt text-slate-50'></i>
                <span class="text-slate-50">လက်ကျန်ငွေ</span>
              </div>
              <div>
                <span class="p-2">{{ wallet }} ကျပ်</span>
              </div>
              <i @click="getUserWallet" class='bx bx-refresh p-2 text-lg cursor-pointer active:animate-spin'></i>
            </div>
            <button class="px-3 py-2 bg-yellow-400 text-slate-900 rounded text-sm 
              tracking-normal hover:bg-yellow-500 hover:text-slate-50 transition-all duration-300 hidden lg:flex items-center space-x-1" @click="redirectPage('Deposite')">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3" />
              </svg>
              <span class="uppercase">ငွေဖြည့်မည်</span>
            </button>
            <button class="px-3 py-2 bg-gray-200 text-slate-900 rounded text-sm 
              tracking-normal lg:flex hidden items-center space-x-1" @click="redirectPage('Withdraw')">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
              </svg>
              <span class="uppercase">ငွေထုတ်မည်</span>
            </button>
            <button
              class="px-3 py-2 bg-slate-900 text-slate-100 rounded text-sm tracking-normal hidden lg:flex items-center space-x-1"
              @click="redirectPage('Editprofile')">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
              </svg>
              <span class="text-sm">{{ name }}</span>
            </button>
            <button
              class="px-3 py-2 bg-slate-700 text-slate-100 rounded text-sm tracking-normal flex items-center space-x-1"
              @click="logout">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
              </svg>
              <span class="uppercase tracking-wider">အကောင့်ထွက်ရန်</span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <!-- mobile bottom nav -->
  <div class="fixed bottom-0 w-full z-50 bg-black/80 backdrop-blur-lg py-8 text-sm rounded-t-3xl text-slate-400 lg:hidden"
    v-show="register">
    <div class="flex justify-between items-center gap-4 px-4">
      <p><span class="text-yellow-200">{{ wallet }}</span> ကျပ်</p>
      <span @click="redirectPage('Deposite')">ငွေဖြည့်မည်</span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-blue-400">
        <path
          d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
        <path
          d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
      </svg>
      <span @click="redirectPage('Withdraw')">ငွေထုတ်မည်</span>
      <span @click="redirectPage('Editprofile')">အကောင့်</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";

export default {
  computed: {
    isOnline() {
      return navigator.onLine;
    }
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  data() {
    return {
      login: !this.$store.state.auth.is_auth,
      register: this.$store.state.auth.is_auth,
      wallet: this.$store.state.auth.wallet,
      name: this.$store.state.auth.userName,
    }
  },
  methods: {
    ...mapActions([
      "auth/logout",
      "user/getUserWallet"
    ]),
    redirectPage(param) {
      this.$router.push('/' + param);
    },
    updateOnlineStatus() {
      this.$forceUpdate(); // Refresh the component to reflect the updated online status
    },
    async getUserWallet() {

      await this["user/getUserWallet"]()
        .then((response) => {

          this.$store.commit('auth/setUserWallet', response.data.data);

        })
        .catch((error) => {
          console.log(error);
        });
    },
    async logout() {

      await this["auth/logout"]()
        .then(() => {

          this.toast.success("Successfully Login");

          this.$router.go();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
}
</script>
