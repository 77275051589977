<template>
  <Menu/>
  <router-view />
  <!-- <footer class="bg-slate-900 bottom-0 w-full text-center p-1">
    <div>
      <p class="text-slate-100 text-xs uppercase">2023. SLNL Company Limited</p>
    </div>
  </footer> -->
</template>

<script>

import Menu from './components/MenuComponent.vue';

export default {
    methods: {
    redirectPage(param) {
      this.$router.push( '/'+ param);
    }
  },
  components: {
    'Menu' : Menu,
  }
}
</script>

<style>

::-webkit-scrollbar{
  background: #333;
  width: 1px;
}

::-webkit-scrollbar-thumb{
  background: rgb(10, 62, 139);
  border-radius: 50px;
}

</style>
