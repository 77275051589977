import { createWebHistory, createRouter } from "vue-router";
import pageNotFoundRoute from "./pageNotFound";

const baseRoutes = [
   {
      path: "/login",
      name: "login",
      component: () => import("@/components/LoginComponent.vue"),
      meta: { isAuthRoute: true },
   },
   {
      path: "/",
      name: "Home",
      component: () => import("@/components/HomeComponent.vue"),
   },
   {
      path: "/2D",
      name: "2D",
      component: () => import("@/components/TwodComponent.vue"),
   },
   {
      path: "/Football",
      name: "Football",
      component: () => import("@/components/FootballComponent.vue"),
   },
   {
      path: "/2DWinner",
      name: "2DWinner",
      component: () => import("@/components/TwodwinnerComponent.vue"),
   },
   {
      path: "/2DResult",
      name: "2D Result",
      component: () => import("@/components/TwodresultComponent.vue"),
   },
   {
      path: "/2DNumber",
      name: "2D-Number",
      component: () => import("@/components/TwodnumberComponent.vue"),
      meta: {
         requiresAuth: true,
         requiresTimeCheck: true,
      },
   },
   {
      path: "/2D-Bet-History",
      name: "2D-Bet-History",
      component: () => import("@/components/TwodBetHistoryComponent.vue"),
      meta: { requiresAuth: true}
   },
   {
      path: "/3D",
      name: "3D",
      component: () => import("@/components/ThreedComponent.vue"),
   },
   {
      path: "/3DNumber",
      name: "3D-Number",
      component: () => import("@/components/ThreednumberComponent.vue"),
      meta: {
         requiresAuth: true,
      },
   },
   {
      path: "/3DWinner",
      name: "3DWinner",
      component: () => import("@/components/ThreedWinnerComponent.vue"),
   },
   {
      path: "/3DResult",
      name: "3D-Result",
      component: () => import("@/components/ThreedresultComponent.vue"),
   },
   {
      path: "/3D-Bet-History",
      name: "3D-Bet-History",
      component: () => import("@/components/ThreedBetHistoryComponent.vue"),
      meta: { requiresAuth: true}
   },
   {
      path: "/Deposite",
      name: "Deposite",
      component: () => import("@/components/DepositeComponent.vue"),
      meta: { requiresAuth: true}
   },
   {
      path: "/Withdraw",
      name: "Withdraw",
      component: () => import("@/components/WithdrawComponent.vue"),
      meta: { requiresAuth: true}
   },
   {
      path: "/UserHistory",
      name: "UserHistory",
      component: () => import("@/components/UserhistoryComponent.vue"),
      meta: { requiresAuth: true}
   },
   {
      path: "/Closedays",
      name: "Close Days",
      component: () => import("@/components/ClosedaysComponent.vue"),
   },
   {
      path: "/Editprofile",
      name: "Edit Profile",
      component: () => import("@/components/EditprofileComponent.vue"),
      meta: { requiresAuth: true}
   },
];

const routes = baseRoutes.concat(pageNotFoundRoute);

const router = createRouter({
   history: createWebHistory(),
   routes,
});

export default router;
