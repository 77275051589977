import { HTTP } from "@/service/http-common";
// initial state
const state = () => ({ });

// getters
const getters = { };

// actions
const actions = {

   updateProfile(_, input) {

      return new Promise((resolve, reject) => {

         const bodyParameter = {
            name: input,
         };

         HTTP.post("update-profile", bodyParameter)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   getUserWallet() {

      return new Promise((resolve, reject) => {

         HTTP.get("user-wallet")
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   getWalletHistories(_,type){
      
      return new Promise((resolve, reject) => {

         HTTP.get("histories/" + type)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });

   }
};

// mutations
const mutations = { };

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
