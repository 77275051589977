import { createStore } from 'vuex';
import auth from "./modules/auth";
import payment from "./modules/payment";
import user from "./modules/user";
import twod from "./modules/twod";
import vuexPersistedstate from "vuex-plugin-persistedstate";

export default createStore({
  modules: {
    auth,
    payment,
    twod,
    user
  },
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  plugins: [vuexPersistedstate()],
})
