import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification";

//css
import './main.css'

// Slider
import 'swiper';

// Animation
import 'animate.css';

// icon
import 'boxicons/css/boxicons.min.css';

// Toast
import "vue-toastification/dist/index.css";

import './registerServiceWorker'


router.beforeEach((to) => {
	if (to.meta.requiresAuth && !store.getters["auth/isAuth"]) {
		return { path: "/login" };
	}

	if (to.meta.isAuthRoute && store.getters["auth/isAuth"]) {
		return { path: "/" };
	}
});

router.beforeEach((to, from, next) => {

	const now = new Date()
	const currentTime = now.getHours() * 60 + now.getMinutes() // convert time to minutes
	const blockedTimes = [
		{ start: 690, end: 750 }, // 11:30 AM to 12:30 PM
		{ start: 1125, end: 1200 }, // 3:45 PM to 5:00 PM
		{ start: 1020, end: 1440 } // after 5:00 PM
	];

	const isBlocked = blockedTimes.some(({ start, end }) => {
		return currentTime >= start && currentTime <= end
	})
	
	if (to.meta.requiresTimeCheck && isBlocked ) {

		next({ name: '2D' }) // navigate to the login page
		
	} else {

		next() // allow navigation to proceed
	}
});

const app = createApp(App);

app.use(store)
app.use(router)
app.use(Toast, {
	transition: "my-custom-fade",
	maxToasts: 20,
	newestOnTop: true,
	position: "bottom-right",
	timeout: 5000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	closeButton: "button",
	icon: true,
	rtl: false,
 });
app.mount('#app')