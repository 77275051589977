import { HTTP } from "@/service/http-common";
// initial state
const state = () => ({ });

// getters
const getters = { };

// actions
const actions = {
   requestWithdraw(_, input) {

      return new Promise((resolve, reject) => {

         const bodyParameter = {
            name: input.name,
            phone: input.phone,
            paymentType: input.paymentType,
            amount: input.amount,
            password: input.password,
         };

         HTTP.post("request-withdraw", bodyParameter)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   depositRequest(_, input) {

      return new Promise((resolve, reject) => {

         const bodyParameter = {
            name: input.name,
            phone: input.phone,
            paymentType: input.paymentType,
            amount: input.amount,
            sixDigit: input.sixDigit,
         };

         HTTP.post("request-deposit", bodyParameter)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   getPaymentTypes() {

      return new Promise((resolve, reject) => {

         HTTP.get("payment-lists")
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
};

// mutations
const mutations = { };

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
